import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setScannedTableId } from '../actions/actions';
import './navbar.scss'; // Update the import statement

const Navbar = ({ tableNo, setScannedTableId }) => {
  const [merchantName, setMerchantName] = useState('');
  
  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    const fetchMerchantInfo = async () => {
      try {
        const merchantId = localStorage.getItem('merchantId'); // Retrieve merchantId from localStorage
        const response = await fetch(`${API_URL}/merchants/merchant`, {
          headers: {
            merchantId: merchantId,
          },
        });
        const data = await response.json();
       
        // Update the merchant name
        setMerchantName(data.username || '');
      } catch (error) {
        console.error('Error fetching merchant information in Navbar:', error);
      }
    };

    fetchMerchantInfo();
  }, []);

  return (
    <header className="navbar">
      <div className="merchant-name">
        <h1>{merchantName}</h1>
      </div>
        <span className='rightside'>Table:{tableNo}</span>
    </header>
  );
};

// Connect Navbar component to the Redux store
const mapStateToProps = () => ({
  tableNo: localStorage.getItem('tableNo'), // Read tableNo from localStorage
});

const mapDispatchToProps = (dispatch) => ({
  setScannedTableId: (tableData) => dispatch(setScannedTableId(tableData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
