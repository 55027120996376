import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { addToCart, setCategories, setFoodsByCategory } from '../actions/actions';
import FoodDetails from './FoodDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './foodlist.scss';

const FoodList = ({ categories, foodsByCategory, addToCart, setCategories, setFoodsByCategory }) => {
  const [selectedFood, setSelectedFood] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categoryContainerRef = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Retrieve the merchantId from local storage
    const merchantId = localStorage.getItem('merchantId');

    // Make sure merchantId is available
    if (!merchantId) {
      console.error('Merchant ID not found in local storage');
      return;
    }

    // Fetch foods with merchantId in the request headers
    fetch(`${apiUrl}/foods/mfoods`, {
      headers: {
        merchantId: ` ${merchantId}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueCategories = [...new Set(data.flatMap((food) => food.categories || []))];
        setCategories(uniqueCategories);

        const foodsGroupedByCategory = {};
        uniqueCategories.forEach((category) => {
          foodsGroupedByCategory[category] = data.filter((food) => (food.categories || []).includes(category));
        });

        setFoodsByCategory(foodsGroupedByCategory);

        // Set the first category as selected by default
        if (uniqueCategories.length > 0) {
          setSelectedCategory(uniqueCategories[0]);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [apiUrl, setCategories, setFoodsByCategory]);

  const handleViewClick = (food) => {
    setSelectedFood(food);
  };

  const handleCloseDetails = () => {
    setSelectedFood(null);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleArrowClick = (direction) => {
    const container = categoryContainerRef.current;
    const width = container.clientWidth;

    if (direction === 'prev') {
      container.scrollLeft -= width;
    } else {
      container.scrollLeft += width;
    }
  };

  const isCurrentDuration = (startTime, endTime) => {
    // Implement your logic to check if the schedule duration is current
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);
    
    return (
      currentHour > startHour ||
      (currentHour === startHour && currentMinute >= startMinute)
    ) && (
      currentHour < endHour ||
      (currentHour === endHour && currentMinute <= endMinute)
    );
  };

  return (
    <div>
      <h2>Food Items by Category</h2>
      <div className="categories-container" ref={categoryContainerRef}>
        {/* Category selection */}
        {categories.map((category) => (
          <div key={category} className={`category ${category === selectedCategory ? 'selected' : ''}`} onClick={() => handleCategoryClick(category)}>
            <h2>{category}</h2>
          </div>
        ))}
        {/* Arrow buttons for navigation */}
        <div className="arrow prev" onClick={() => handleArrowClick('prev')}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="arrow next" onClick={() => handleArrowClick('next')}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      {/* Display food items */}
      
      <div className="food-container">
        {foodsByCategory[selectedCategory]?.map((food) => {
          if (food.schedulingEnabled && food.schedule.length > 0) {
            if (isCurrentDuration(food.schedule[0].startTime, food.schedule[0].endTime)) {
              return (
                <div key={food._id} className={`food-item ${!isCurrentDuration(food.schedule[0].startTime, food.schedule[0].endTime) ? 'blurred' : ''}`}>
                  <div className="image-wrapper">
                    <img src={food.img} alt={food.title} />
                  </div>
                  <div className="food-details">
                    <h3>{food.title}</h3>
                    <p className="price">RM {food.price.toFixed(2)}</p>
                    <p > {food.categories}</p>
                    <p className="schedule">Schedule: {food.schedule[0].timeOfDay} ({food.schedule[0].startTime} - {food.schedule[0].endTime})</p>
                    {food.inStock ? (
                      <button className="button" onClick={() => handleViewClick(food)}>Add Cart</button>
                    ) : (
                      <p className="out-of-stock">Out of Stock</p>
                    )}
                  </div>
                </div>
              );
            } else {
              return (
                <div key={food._id} className={`food-item blurred`}>
                  <div className="image-wrapper">
                    <img src={food.img} alt={food.title} />
                  </div>
                  <div className="food-details">
                    <h3>{food.title}</h3>
                    <p className="price">RM {food.price.toFixed(2)}</p>
                    <p className="schedule">Schedule: N/A</p>
                   
                  </div>
                </div>
              );
            }
          } else {
            return (
              <div key={food._id} className={`food-item`}>
                <div className="image-wrapper">
                  <img src={food.img} alt={food.title} />
                </div>
                <div className="food-details">
                  <h3>{food.title}</h3>
                  <p className="price">RM {food.price.toFixed(2)}</p>
                  <p>{food.categories}</p>
                 
                  {food.inStock ? (
                    <button className="button" onClick={() => handleViewClick(food)}>Add Cart</button>
                  ) : (
                    <p className="out-of-stock">Out of Stock</p>
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>
      {/* Display selected food details */}
      {selectedFood && (
        <FoodDetails food={selectedFood} onClose={handleCloseDetails} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  foodsByCategory: state.foodsByCategory,
});

const mapDispatchToProps = {
  addToCart,
  setCategories,
  setFoodsByCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(FoodList);
