import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setScannedTableId } from '../actions/actions';
import './table.scss'; // Import the SCSS file

function Table({ tableId, setScannedTableId }) {
  const [tableInfo, setTableInfo] = useState(null);
  const { tableId: routeTableId } = useParams();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (routeTableId && routeTableId !== tableId) {
      setScannedTableId(routeTableId);
    }

    const fetchTableInfo = async () => {
      try {
        if (tableId !== null) {
          const response = await fetch(`${apiUrl}/tables/qrurl/${tableId}`);
          const data = await response.json();
          console.log('Fetched table information:', data);
          setTableInfo(data);
        }
      } catch (error) {
        console.error('Error fetching table information:', error);
      }
    };

    if (tableId !== null) {
      fetchTableInfo();
    }
  }, [tableId, routeTableId, setScannedTableId]);

  const handlePlaceOrder = () => {
    // Save tableId to localStorage when placing an order
    localStorage.setItem('scannedTableId', tableId);

    // Save merchantId to localStorage if available
    if (tableInfo && tableInfo.merchantId) {
      localStorage.setItem('merchantId', tableInfo.merchantId);
    }
    if (tableInfo && tableInfo.tableNo) {
      localStorage.setItem('tableNo', tableInfo.tableNo);
    }

    navigate('/');
  };

  if (tableId === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="table-container">
    
      {tableInfo && <p>Table No: {tableInfo.tableNo}</p>}
      <button onClick={handlePlaceOrder}>Finds Foods</button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  tableId: state.scannedTableId,
});

const mapDispatchToProps = (dispatch) => ({
  setScannedTableId: (tableId) => dispatch(setScannedTableId(tableId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
