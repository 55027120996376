import React from 'react';
import '../App.css';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import FoodList from './FoodList';


function Layout() {
  return (
    <div className="App">
   
        <Navbar />
        <FoodList />
       
        <Footer />

    </div>
  );
}

export default Layout;