import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addToCart } from '../actions/actions';
import './foodDetails.scss';

const FoodDetails = ({ food, addToCart, onClose }) => {
  const [selectedRequests, setSelectedRequests] = useState([]);

  const handleToggleRequest = (requestId) => {
    // Check if the request is already selected
    if (selectedRequests.includes(requestId)) {
      setSelectedRequests(selectedRequests.filter(id => id !== requestId)); // Deselect request
    } else {
      setSelectedRequests([...selectedRequests, requestId]); // Select request
    }
  };

  const handleAddToCart = () => {
    // Create a new item object with selected requests
    const newItem = {
      ...food,
      requests: food.requests.filter(request => selectedRequests.includes(request.id))
    };
    addToCart(newItem);
    onClose();
  };

  return (
    <div className="food-details-container">
      <h2>{food.title} Details</h2>
      <div className="food-details-content">
        <img src={food.img} alt={food.title} />
        <p>Description: {food.desc}</p>
        <p className="price">Price: RM {food.price.toFixed(2)}</p>
        <p>Requests:</p>
        <div className="requests-container">
          {food?.requests?.map((request) => (
            <div key={request.id}>
              <input
                type="checkbox"
                id={`request-${request.id}`}
                checked={selectedRequests.includes(request.id)}
                onChange={() => handleToggleRequest(request.id)}
              />
              <label htmlFor={`request-${request.id}`}>
                {request.name} RM {request.addOnPrice}
              </label>
            </div>
          ))}
        </div>
        <div className="button-container">
          <button onClick={handleAddToCart}>Add to Cart</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { addToCart })(FoodDetails);
